import { render, staticRenderFns } from "./Editproductissues.vue?vue&type=template&id=30ccc0d0&scoped=true"
import script from "./Editproductissues.vue?vue&type=script&lang=js"
export * from "./Editproductissues.vue?vue&type=script&lang=js"
import style0 from "./Editproductissues.vue?vue&type=style&index=0&id=30ccc0d0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ccc0d0",
  null
  
)

export default component.exports